<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Product/Inventory Configurator</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="loaders.form"
            style="margin-left: 10px;"
        ></v-progress-circular>
        <v-btn class="ml-2" small color="success" :loading="loaders.saveAll" :disabled="loaders.form" @click="saveAll">Save All</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form :disabled="loaders.form">
          <v-card outlined>
            <v-card-text>
              <v-text-field label="Quantity Precision" v-model="data.globals.quantityPrecision" outlined dense/>
              <span class="d-flex flex-row">
                <v-checkbox label="Default Allow Fractional?" v-model="data.globals.defaultAllowFractional" outlined dense/>
                <v-text-field label="Default Factor of Quantity" hint="This is force converted using the Quantity Precision so make sure to adjust the Quantity Precision AND SAVE accordingly first." persistent-hint v-model="data.globals.defaultFactorOfQuantity" outlined dense/>
                <v-btn class="mt-2 ml-2" :loading="loaders.overwriteProductValues[0]" @click="overwriteProductValues({metadata: {quantityStepAmount: utils.pff(data.globals.defaultFactorOfQuantity), allowFractional: data.globals.defaultAllowFractional}}, {defaultFactorOfQuantity: utils.pff(data.globals.defaultFactorOfQuantity), defaultAllowFractional: data.globals.defaultAllowFractional})" color="info" small>Overwrite For All Products</v-btn>
              </span>
              <v-checkbox label="Enforce Min Sale Quantity?" v-model="data.globals.enforceMinSaleQuantity" outlined dense/>
            </v-card-text>
          </v-card>
          <v-card outlined>
            <v-card-text>
              <v-checkbox label="Is Tax Type Editable?" v-model="data.globals.productTaxTypeEditable" outlined dense/>
              <v-select label="Default Tax Type" v-model="data.globals.productTaxTypeDefaultValue" :items="data.taxTypes" outlined dense/>
              <v-checkbox label="Show Minimal Tax Type?" v-model="data.globals.productTaxTypeMinimal" outlined dense/>
              <v-select label="Minimal Tax Types (Only 2)" v-model="data.globals.productTaxTypeMinimalValues" :items="data.taxTypes" clearable outlined dense multiple/>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-card-text>
            <v-checkbox label="Show Quantity on Product Index?" v-model="data.globals.showQuantityOnProductIndex" outlined dense/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import utils from "../../plugins/helpers"

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: {
        globals: {},
        taxTypes: []
      },
      validForm: false,

      loaders: {
        form: false,
        saveAll: false,
        overwriteProductValues: [false]
      },

      taxTypes: [],

      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Hidden', value: 'hidden' },
        { text: 'Transactions', value: 'numTransactions' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try {
      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }
  },
  methods: {
    async fetchData(){
      try{
        this.loaders.form = true;

        let res = await axios.get(`${this.getEndpoint}/api/inventoryConfigurator`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data

        console.log(this.data)
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    async overwriteProductValues(data, global=null){
      try{
        this.loaders.form = true;
        this.loaders.overwriteProductValues[0] = true;

        let res = await axios.put(`${this.getEndpoint}/api/inventoryConfigurator/overwriteProductValues`, {data, global})
        if(res.data.error) throw res.data.error

        console.log(res.data)

        this.snack("Overwrite Successful.")
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
        this.loaders.overwriteProductValues[0] = false;
      }
    },
    async saveAll(){
      try{
        this.loaders.form = true;

        for(let key of Object.keys(this.data.globals)){
          if(typeof this.data.globals[key]!=='boolean' && !Array.isArray(this.data.globals[key])){
            if(this.data.globals[key]===null || this.data.globals[key]===undefined || this.data.globals[key].length===0){
              throw `Value ${key} cannot be empty`;
            }
          }
        }

        if(parseInt(this.data.globals.quantityPrecision)!==parseFloat(this.data.globals.quantityPrecision)) throw "Quantity Precision must be an integer.";

        this.data.globals.defaultFactorOfQuantity = utils.pff(this.data.globals.defaultFactorOfQuantity, parseInt(this.data.globals.quantityPrecision))

        let res = await axios.put(`${this.getEndpoint}/api/inventoryConfigurator/saveAll`, {data: this.data})
        if(res.data.error) throw res.data.error

        this.snack(`All saved!`, "success");

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
